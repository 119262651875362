<template>
    <div class="mainDashboardContainer">
        <b-row v-if="client.status" class="content-header">
          <b-col class="content-header-left mb-2" cols="10">
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                  <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                    :variant="client.logo ? `white` : `primary`" />
                  {{ client.name }}
                </h2>
                <div class="breadcrumb-wrapper breadcrumb-container">
                  <b-breadcrumb>
                    <b-breadcrumb-item to="/">
                      <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                    </b-breadcrumb-item>
                    <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                      class="text-white">
                      {{ item.text }} 
                    </b-breadcrumb-item>
                  </b-breadcrumb>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
        <div v-if="client.status &&
          client.integrations.googleAds &&
          client.googleAdsCustomerID
          ">
          <b-overlay class="dashboard-overlay" :show="isClientLoad" rounded="sm">
            <date-selector :client="client._id" :slug="$route.params.slug"  @fetchProjectData="fetchProjectWiseData" @fetchAllDataWiseData="fetchAllDataWiseData" :chartSummaryData="totalLeadSquaredData.cummulativeInsights" :businessType="client.businessType"  :iconCardType="iconType" :groupCardFlag="isTotalIsLoad"/>
            <!-- <b-card class="tabs-container screenAlignmentFacbook p-1"> 
                <b-tabs fill>
                  <b-tab
                    title="Project Insights"
                    active
                    @click="changeTab('ProjectInsights')"
                  ></b-tab>
                </b-tabs>
              </b-card> -->
              <div >
                <div v-if="
                  this.client &&
                  this.client.accessConfiguration &&
                  this.client.accessConfiguration.projects.enabled
                ">
                  <b-row >
                    <b-col cols="12">
                      <b-card>
                        <div v-if="this.isProjectWiseIsLoad" class="spinnerCentered">
                          <multiChannelSpinner />
                        </div>
                        <div v-else>
                          <MultiHeaderProjectChannel :projectName="'Project(s) Summary ' +
                          this.formatDateDisplay(this.startDateProjectName || this.selectedStartDate) +
                          ' to ' +
                          this.formatDateDisplay(this.endDateProjectName || this.selectedEndDate)
                          "  v-if="
                            this.client &&
                            this.client.accessConfiguration &&
                            this.client.accessConfiguration.projects.enabled
                          " type="leadSquareAds" :businessTypeProject="this.client.businessType[0]"
                            :tableData="projectWiseData" :clientName="this.client.name"/>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
          </b-overlay>
        </div>
      </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTabs,
  BTab,
} from "bootstrap-vue";
import axios from "axios";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { getUserToken } from "@/auth/utils";
import DateSelector from "./DateSelector.vue";
import moment from "moment";
import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
export default {
    data() {
    return {
      totalLeadSquaredData:{},
      selectedStartDate:"",
      selectedEndDate:"",
      productProjectNamesChange:[],
      productProjectIntialNamesChange:[],
      productWiseTypeOptions:[],
      creativeOptions:[],
      intiallOptionsForGroup:[],
      optionsLength:0,
      zoneWiseLength:0,
      selectedZoneTypeForFilter:[],
      selectedProductType:"all",
      adRunningError:"",
      zoneWiseTypeOptions:null,
      zoneWiseTypeOptionsFlag:false,
      openScheduleModal:false,
      isProductDatafetched:false,
      isZonewiseDatafetched:false,
      productWiseData:[],
      zoneWiseData:[],
      startDateProjectName:"",
      endDateProjectName:"",
      tabName: "",
      projectsTab: false,
      isProjectDataLoad:false,
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      errorMessage: "",
      iconType: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      isReportDataLoading: true,
      isClientLoad: true,
      campaigns: [],
      charts: {
        data: [],
        totalData: {},
      },
      graphs: [],
      currentDateRange: "",
      chartData: [],
      projectData:[],
      selectedZoneTypeForFilter:[],
      zoneListChange:[],
      projectWiseData:[],
      isProjectWiseIsLoad:false,
      isTotalIsLoad:false
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    BOverlay,
    BTabs,
    BTab,
    DateSelector,
    MultiHeaderProjectChannel,
    multiChannelSpinner
  },
  methods:{
    fetchProjectWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      console.log(dateRange, startDate, endDate);
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
   
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/overall-project-insights?clientID=${this.client._id}&channel=leadSquared&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.projectWiseData = response.data;
          this.isProjectWiseIsLoad = false;
        })
        .catch(error => {
          this.isProjectWiseIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchAllDataWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isTotalIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
   
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/leadSquared/cummulative-insights?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.totalLeadSquaredData = response.data
          this.isTotalIsLoad = false;
        })
        .catch(error => {
          this.isTotalIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    changeTab(name) {
      this.tabName = name;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');
    const updatedDate = `${day}-${month}-${year}`;
    return updatedDate;
   },
    getClient() {
      const slug = this.$route.params.slug;
      this.isClientLoad = true
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to:`/channels-dashboard/${response.data.client.slug}`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "LeadSquared Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/google-ads`,
            active: true,
          });
          if (
            response.data.client &&
            response.data.client.integrations.leadSquared &&
            response.data.client.leadSquared.accessKey
          ) {
            this.fetchProjectWiseData()
            this.fetchAllDataWiseData()
          }
          this.isClientLoad = false
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
          this.isClientLoad = false
        });
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  mounted() {
    this.tabName = "Graphs";
    this.getClient();
  },
}
</script>
<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

.screenAlignmentFacbook {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>