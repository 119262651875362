var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainDashboardContainer"},[(_vm.client.status)?_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"10"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0 text-white"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"32","src":_vm.client.logo,"text":_vm.avatarText(_vm.client.name),"variant":_vm.client.logo ? "white" : "primary"}}),_vm._v(" "+_vm._s(_vm.client.name)+" ")],1),_c('div',{staticClass:"breadcrumb-wrapper breadcrumb-container"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":"/"}},[_c('feather-icon',{staticClass:"align-text-top text-white",attrs:{"icon":"HomeIcon","size":"16"}})],1),_vm._l((_vm.breadcrumbs),function(item){return _c('b-breadcrumb-item',{key:item.text,staticClass:"text-white",attrs:{"active":item.active,"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)])],1)],1),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e(),(_vm.client.status &&
      _vm.client.integrations.googleAds &&
      _vm.client.googleAdsCustomerID
      )?_c('div',[_c('b-overlay',{staticClass:"dashboard-overlay",attrs:{"show":_vm.isClientLoad,"rounded":"sm"}},[_c('date-selector',{attrs:{"client":_vm.client._id,"slug":_vm.$route.params.slug,"chartSummaryData":_vm.totalLeadSquaredData.cummulativeInsights,"businessType":_vm.client.businessType,"iconCardType":_vm.iconType,"groupCardFlag":_vm.isTotalIsLoad},on:{"fetchProjectData":_vm.fetchProjectWiseData,"fetchAllDataWiseData":_vm.fetchAllDataWiseData}}),_c('div',[(
              this.client &&
              this.client.accessConfiguration &&
              this.client.accessConfiguration.projects.enabled
            )?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[(this.isProjectWiseIsLoad)?_c('div',{staticClass:"spinnerCentered"},[_c('multiChannelSpinner')],1):_c('div',[(
                        this.client &&
                        this.client.accessConfiguration &&
                        this.client.accessConfiguration.projects.enabled
                      )?_c('MultiHeaderProjectChannel',{attrs:{"projectName":'Project(s) Summary ' +
                      this.formatDateDisplay(this.startDateProjectName || this.selectedStartDate) +
                      ' to ' +
                      this.formatDateDisplay(this.endDateProjectName || this.selectedEndDate),"type":"leadSquareAds","businessTypeProject":this.client.businessType[0],"tableData":_vm.projectWiseData,"clientName":this.client.name}}):_vm._e()],1)])],1)],1)],1):_vm._e()])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }